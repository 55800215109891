@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

.App {
  text-align: center;
}

body {
  background-color: #5d1343;
}

#surveyPage{
  display: grid;
  grid: "subjectList qrCodeArea" 1fr
        "githubIconArea githubIconArea" 50px
        /1fr 0.7fr; 
  min-height: 100svh;
}

#subjectList{
  grid-area: subjectList;
}

#qrCodeArea{
  grid-area: qrCodeArea;
}

#githubIconArea{
  grid-area: githubIconArea;
  padding: 8px 8px;
}

#donePage{
  display: grid;
  grid: "done" 1fr
        "githubIconArea" 50px; 
  min-height: 100svh;
}

#done {
  grid-area: done;
  color: white;
  font-size: 4ch;
  padding: 20px;
}

.githubIcon{
  cursor: pointer;
  mask: url(./github-mark-white.svg);
  background: rgb(255, 255, 255);
  width: 39px;
  height: 100%;
  mask-repeat: no-repeat;
  background-position: center;
  float: right;
}

.white-rounded-corners {
  padding: 1rem;
  width: 90%;
  margin: 25px auto;
  background-color: white;
  border-radius: 12px;
  border: 3px solid #bc6d96;
}

textarea {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}

#qrCode {
  width: 100%;
  height: 100%;
}

#doneSubtext{
  font-size: 1ch; 
}

table td {
  border: 5px solid white;
}